/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('source-sans-pro/SourceSansPro-Regular.ttf') format('truetype');
}
/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('source-sans-pro/SourceSansPro-Semibold.ttf') format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('source-sans-pro/SourceSansPro-Bold.ttf') format('truetype');
}