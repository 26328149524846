:root {
    --primary-color: #002D3A;
    --secondary-color: #D2E2E7;
    --ice: #D2E2E7;
    --white: #fff;
    --danger-text: #DB0032;
    --red: #dc2b2b;
    --warning-text: #FFD752;
    --grey-200: #888888;
    --teal-800: #00586F;
    --purple-700: #595F73;
    --purple-600: #7E7FAB;
    --purple-400: #815474;
    --purple-200: #DDB38D;
    --teal-100: #618A9A;
    --purple-900: #252C32;
    --purple-1000: #222944;
    --dark: #000000;
    --light: #F9F8F9;
    --ice-100: #EFEFF5;
    --ice-200: #E7E1E5;
}


*,
::after,
::before {
    box-sizing: border-box;
}

html {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1rem;
    color: var(--dark);

}

body {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    color: var(--dark);
    margin: 0px;
    padding: 0px;
    line-height: 1.5;
    background: #F3F8F9;
}

.btn:focus,
button:focus,
input:focus,
select,
textarea,
.form-control,
.btn.focus {
    outline: 0px;
    box-shadow: none;
}

.btn:disabled {
    cursor: not-allowed !important;
}

a {
    color: var(--dark);
    text-decoration: none;
}

a:hover, a:focus, a:active {
    text-decoration: none;
}


::-moz-selection {
    background: var(--primary-color) !important;
    color: #ffffff !important;
}

::selection {
    background: var(--primary-color) !important;
    color: #ffffff !important;
}

::-moz-placeholder {
    color: var(--teal-100);
}

:-ms-input-placeholder {
    color: var(--teal-100);
}

::placeholder {
    color: var(--teal-100);
}

.bg-dark {
    background-color: var(--dark) !important;
}

.bg-light {
    background: #F3F8F9 !important;
}
.bg-ice-100 {
    background: var(--ice-100) !important;
}
.text-grey-200{
    color: var(--grey-200);
}
.dropdown-menu{
    background: #FFFFFF;
    border: 1px solid #D2E2E7;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}
.profile-dropdown .dropdown-menu{
    margin-top:130px !important;
    left: -115px !important;
}

.main-navbar-link{
    height: 40px;
    border-bottom: 3px solid #00586F !important;
}
.bg-primary {
    background-color: var(--primary-color) !important;
    background: var(--primary-color) !important;
}
.bg-secondary {
    background-color: var(--secondary-color) !important;
    background: var(--secondary-color) !important;
}
.bg-ice-200{
    background: var(--ice-200) !important;
}


.text-serif {
    font-family: 'Cormorant', serif !important;
}
hr{
    opacity: 1;
    border-color: var(--ice) !important;
}
.border-top-light{
    border-top: 1px solid #EFEAEE !important;
}
/********TYPOGRAPHY********/

.text-transform-none {
    text-transform: none !important;
}

.text-dark {
    color: var(--purple-900) !important;
}

.text-teal-800 {
    color: var(--teal-800) !important;
}

.text-purple-600 {
    color: var(--purple-600) !important;
}

.text-purple-700 {
    color: var(--purple-700) !important;
}

.text-teal-400 {
    color: #5490A0 !important;
}
.text-teal-100{
    color: var(--teal-100) !important;
}
.text-purple-200 {
    color: var(--purple-200) !important;
}
.text-ice{
    color:#A8C7CF !important;
}
p.text-primary,
.text-primary {
    color: #00586F !important;
}


.text-secondary {
    color: var(--secondary-color) !important;
}

.heading-lg {

    font-size: 45px !important;
}
.ls-lg{
    letter-spacing: 0.2em;
}

.heading-md {
    font-size: 40px !important;
}

.heading-sm {
    line-height: 1.4 !important;
    font-size: 28px !important;
}

.text-sm {
    font-size: 12px !important;
}

.text-xs{
    font-size: 10px !important;
}

.text-md {
    font-size: 14px !important;
}

.text-base {
    font-size: 16px !important;
}

.text-lg {
    font-size: 20px !important;
}

.text-xl {
    font-size: 24px !important;
}


.text-18 {
    font-size: 18px !important;
}

.l-2 {
    line-height: 2 !important;
}

.l-md {
    line-height: 1.7 !important;
}

.l-1 {
    line-height: 1 !important;
}
.custom-textarea{
    width: 100%;
    transition: all .2s;
    color: var(--dark);
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    box-shadow: none !important;
    padding: 5px 10px;
    min-height:234px !important;
    background: #FFFFFF;
    border: 1px solid #D2E2E7;
    border-radius: 5px;
}
.custom-input{
    width: 100%;
    transition: all .2s;
    color: var(--dark);
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    box-shadow: none !important;
    padding: 5px 10px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #D2E2E7;
    border-radius: 5px;
}



.custom-input:active,
.custom-input:focus,
.custom-input:hover{
    border: 1px solid #D2E2E7 !important;
    box-shadow: none !important;
    outline: 0 !important;
}

/*HEADER*/
.main-navbar {
    border-bottom: 1px solid #D6E3E8 !important;
}
.main-navbar .container-fluid,
.main-navbar {
    height: 40px !important;
}
.main-navbar .main-logo{

    border-right: 1px solid var(--secondary-color) !important;
}

.main-navbar .nav-link{
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}
.custom-btn{
    height: 30px;
    font-weight: 600;
    padding: 6px 15px;
    font-size: 12px;
    line-height: 18px;
}

.truncate-select{
 width:80px !important;
}

.btn-expand-group button.custom-btn-active{
    background: #1F4C62!important;
    border-color: #1F4C62 !important;
    width: 40px;
    color:white !important;
}


.dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: 0.125rem;
}

.btn-expand-group button{
    background: #fff !important;
    border-color: #1F4C62 !important;
    width: 40px;
    color: #1F4C62 !important;
}

.btn-danger{
    background: var(--danger-text)!important;
    border-color: var(--danger-text)!important;
}

.unit-collapse-area{
    border-bottom: 1px solid var(--primary-color) !important;
}
.icon-24{
    width: 24px;
    height: 24px;
}
.icon-28{
    width: 28px;
    border: 1px solid #A8C7CF !important;
    height: 28px;
}
.icon-28 img,
.icon-24 img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.main-tabs.nav-tabs{
    border-color: #D2E2E7 !important;
}

.main-tabs .nav-link{
    padding: 8px 16px;
    background: #4D8A9A;
    color: white;
    border-radius:  0 !important;
    border-width: 1px 1px 0 1px;
    border-style: solid;
    border-color: #D2E2E7;
}
.main-tabs .nav-item:last-child .nav-link{
    border-width: 1px 1px 0 1px !important;
    border-radius: 0px 10px 0px 0 !important;
}
.main-tabs .nav-item:nth-child(1) .nav-link{
    border-radius: 10px 0 0 0 !important;
    border-width: 1px 1px 0 1px;
}
.main-tabs .nav-item:nth-child(2) .nav-link{
    border-radius:  0 !important;
}
.main-tabs .nav-link.active{
    color: #00586F !important;
    background: #F3F8F9 !important;
    border-color: #D2E2E7;
}
.tabs-input-search{
    width: 300px;
}
.profile-dropdown{
    height: 40px;
    border-left: 1px solid var(--secondary-color)!important;
}
.main-navbar-right,
.main-navbar-right{
    border-left: 1px solid var(--secondary-color)!important;
    min-height: 40px;
}
.arrow-none:after{
    display: none;
}
.btn-ice{
    background: #F3F8F9 !important;
    border-color: #A8C7CF !important;
}
.custom-filter{
    width: 90px;
}
.main-blue-header{
    height: 40px;
    border: 1px solid #D2E2E7;
    border-width: 1px 0 !important;
}
.border-right-ice{
    border-right: 1px solid #D2E2E7 !important;
}

.form-check-input:checked {
    background-color: #00586F !important;
    border-color: #00586F !important;
}

.custom-tags{
    font-size: 12px;
    line-height: 16px;
    color: #5490A0;
    padding: 4px 6px;
    background: #F3F8F9;
    border: 1px solid #D2E2E7;
    border-radius: 3px;
}

.custom-tags-assessment{
    font-size: 12px;
    font-weight:700;
    line-height: 16px;
    padding: 4px 6px;
    background: #F3F8F9;
    border: 1px solid #D2E2E7;
    border-radius: 3px;
}

.btn-expand-group button .custom-btn-active{
    background: #1F4C62!important;
    border-color: #1F4C62 !important;
    width: 40px;
    color:white !important;
}

.expanded {
    max-height: 60px;
    overflow: auto;
}

.expanded::-webkit-scrollbar{
    width:6px;
}
.expanded::-webkit-scrollbar-track{
    background: white;
}


.expanded::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.512804);}


@media (max-width: 1200px) {
    .main-navbar-right{
        border-left: 0 !important;
    }
}
.navbar-toggler:focus,
.navbar-toggler{
    box-shadow: none !important;
    border: 0 !important;
    padding: 0 !important;
}

.custom-scroll-nav{
    border-color: #D2E2E7 !important;
    border-width: 1px 0 !important;
    border-style: solid !important;
    overflow-x: auto;
}
.custom-scroll-nav-divider{
    height: 20px;
    border-left: 2px solid #B9D2DC !important;
}
.custom-scroll-nav::-webkit-scrollbar{
    height: 3px;
}
.custom-scroll-nav::-webkit-scrollbar-track{
    background: white;
}
.custom-tooltip{
    --bs-tooltip-bg: var(--primary-color);
    font-size: 11px !important;
}
.custom-scroll-nav::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.512804);
    width: 20px;
    position: absolute;
}
.custom-scroll-nav-item{
    height: 36px;
    line-height: 36px;
    cursor: pointer;
}
.custom-scroll-nav-item.active{
    background: #E2F1F4 !important;
    border-bottom: 2px solid #00586F !important;
    color: #00586F !important;
}


.custom-table td,
.custom-table th {
    font-weight: 400 !important;
    color: #000000;
    border: 1px solid #D2E2E7 !important;
    font-size: 12px !important;
}

.badge{
    cursor: pointer;
    border-radius: 3px !important;
    padding: 4px;
    height: 25px;
    color: white !important;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */
    text-transform: uppercase;
}
.badge.bg-blue{
    background: #1E4493 !important;
    border: 0.5px solid #132549 !important;
}
.badge.bg-purple{
    background: #674FA0 !important;
    border: 0.5px solid #4E3B7D !important;
}
.badge.bg-pink{
    background: #E6549E !important;
    border: 0.5px solid #BC4280 !important;
}
.badge.bg-success{
    background: #6FA943 !important;
    border: 0.5px solid #517B30 !important;
}
.badge.bg-info{
    background: #389FB7 !important;
    border: 0.5px solid #277183 !important;
}
.badge.bg-warning{
    background: #FAA330 !important;
    border: 0.5px solid #C98327 !important;
}

.analysis-cards{
    background: #FFFFFF;
    border: 1px solid #D2E2E7;
    border-radius: 5px;
    padding: 12px;
}
.analysis-cards h4{
    font-size: 36px;
}

.page-wrapper{
    margin-left: -150px;
}



/*honey comb login page*/
.hexagon {
    position: relative;
    min-height: 208px;
    min-width: 180px;
    background: #D6E5EB;
    margin: 5px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}
.hexagon,
.hexagon:before,
.double:after {
    /* -webkit-clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%); */
    clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
}
.hexagon:before {
    top: 3px;
    left: 3px;
    height: calc(100% - 6px);
    width: calc(100% - 6px);
    background: white !important;
}
.hexagon:before {
    position: absolute;
    content: "";
}
.hex-wrapper .hex-row.second {
    margin-top: -50px;
    margin-left: 95px;
}

.double:after {
    top: 20px;
    left: 20px;
    height: calc(100% - 40px);
    width: calc(100% - 40px);
    background: #D7E8EF;
}
.hexagon,
.hexagon:before,
.double:after {
    -webkit-clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
    clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
}
.hexagon:before,
.double:after {
    position: absolute;
    content: "";
}

.academic.hexagon:before {
    background: #D05792 !important;
    cursor: pointer;
}
.no-border.hexagon:before {
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
}

.competencies-label {
    color: #FFFFFF;
    font-size: 19px;
    font-weight: 700;
    z-index: 1;
    cursor: pointer;
}
.metacognition.hexagon:before {
    background: #EC9D39 !important;
    cursor: pointer;
}
.hexRow {
    position: relative;
    display: -webkit-box;
    display: flex;
    cursor: default;
}

.hex-wrapper .hex-row.third {
    margin-top: -50px;
    margin-right: 117px;
}
.wellbeing.hexagon:before {
    background: #709C43 !important;
    cursor: pointer;
}
.service.hexagon:before {
    background: #4B92AC !important;
    cursor: pointer;
}
.hexItem {
    height: 208px;
    min-width: 180px;
    width: 180px;
    margin: 5px;
    outline: 1px solid transparent;
    overflow: visible;
}
.digital.hexagon:before {
    background: #233C85 !important;
    cursor: pointer;
}
.enterprise.hexagon:before {
    background: #594893 !important;
    cursor: pointer;
}
.hex-row.last {
    margin-top: -50px;
}
.page-wrapper-right{
    max-width: 430px;
}
.login-logo{
    width: 200px;
}
.page-wrapper-right h2{
    font-size: 36px;
}
.login-button{
    height: 50px;
    background: #71DB4B !important;
    border-color: #71DB4B !important;
    padding: 0 30px;
    line-height: 50px;
    font-size: 16px;

}
.login-button-light{
    height: 50px;
    background: #F3F8F9 !important;
    border: 0.5px solid #A8C7CF !important;
    padding: 0 30px;
    line-height: 40px;
    color: #00586F !important;
    font-size: 16px;
}
.login-button .login-icon{
    width: 30px;
    height: 30px;
    background: #65C841;
}

@media (max-width: 1280px) {
    .hex-wrapper{
        transform: scale(0.9) translate(-30px, -30px) !important;
    }
}
@media (max-width: 1199px) {
    .hex-wrapper{
        transform: scale(0.7) translate(-100px, -116px) !important;
    }
}
@media (max-width: 992px) {
    .hex-wrapper{
        transform: scale(0.6) translate(-100px, -116px) !important;
    }
    .filter-input{
        width: 100% !important;
    }
    .main-navbar .container-fluid,
    .main-navbar {
        height: auto !important;
    }
    .main-navbar .main-logo{
        border: 0 !important;
    }
    .main-navbar-right{
        height: auto !important;
    }
    .main-navbar .nav-link{
        padding: 8px 0rem !important;
    }
    .custom-filter{
        width: 100% !important;
    }

}

@media (max-width: 767px) {
    .page-wrapper-left-outer{
        overflow-x: hidden !important;
        display: flex;
        justify-content: center;
    }
    .hex-wrapper {
        transform: scale(0.57) translate(0px, -230px) !important;
    }
    .main-blue-header{
        height: auto !important;
    }
    .tabs-input-search{
        width: 100% !important;
    }
    .page-wrapper-right{
        margin-top: -250px !important;
    }
    .page-wrapper {
        margin-left: -40px !important;
    }
}
